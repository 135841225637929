/* eslint-disable */
import React from 'react';
import Layout from '../components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Experiencetab from '../components/Experiencetab';
import Gettouch from '../components/Gettouch';
import Headinfo from '../components/Headinfo';
import Meeting from '../components/Meeting';
import ServiceMeshTop from '../components/servicemeshtop';
import Louisbester from '../assets/images/Louis-bester.jpg';
import Stephangrobler from '../assets/images/Stephan-grobler.jpg';
import { Helmet } from 'react-helmet';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const servicemesh = (props) => {
  const servicemeshImages = useStaticQuery(graphql`
    query servicemeshImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='experienceurbianpage cerulean'>
      <Layout>
        <Helmet>
          <title>Urbian | Service mesh</title>
        </Helmet>
        <ServiceMeshTop></ServiceMeshTop>
        <Container className='spb-5 border-bottom'>
          <Row>
            <Col lg='6'>
              <div className='sp-5'>
                <h3>What is it?</h3>
                <p>
                  Service Mesh is an approach to orchestrating microservices and
                  managing their networking at scale.
                </p>
                <p>
                  When designed and deployed well, it helps you solve some of
                  the major challenges of complex system architectures – things
                  like service discovery, security, traffic management, and
                  observability.
                </p>
                <p>
                  Once you’re running a microservices architecture, you know
                  you’ll face requirements in the future such as rapid scaling
                  and extending with new features to meet business needs. With
                  each microservice able to evolve independently, it is highly
                  likely your architecture will look very different a year after
                  launch.
                </p>
                <p>
                  Running reliably and safely at-scale gets increasingly
                  difficult as more services are added, with service
                  connectivity, cross-cutting concerns, security, and
                  load-balancing all impacting a distributed system.
                </p>
              </div>
              <div className='sp-5'>
                <h3>Who is it for?</h3>
                <p>
                  Learning about Service Mesh benefits CTOs, engineers,
                  architects and technical product leads, especially in
                  large-scale environments, who want to:
                </p>
                <ul>
                  <li>Add business value instead of connecting services</li>
                  <li>
                    Gain visibility so problems are easier to recognise and
                    diagnose
                  </li>
                  <li>
                    Develop highly resilient systems that route around issues
                  </li>
                  <li>Secure and optimize service communications</li>
                </ul>
              </div>
              <div className='sp-5'>
                <h3>What will you learn?</h3>
                <ul>
                  <li>Learn what Service Mesh is</li>
                  <li>
                    Discover how to benefit by cutting complexity and improving
                    efficiency
                  </li>
                  <li>
                    Know where to start with Service Mesh in your organisation
                  </li>
                </ul>
              </div>
              <h3>Who will run it?</h3>
              <p>A Product Owner or Delivery Lead.</p>
              <div className='team-grid'>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={servicemeshImages.louis.childImageSharp.sizes}
                      alt='Louis Bester'
                    />
                  </div>
                  <h6>Louis Bester</h6>
                  <span>Product Owner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={servicemeshImages.jay.childImageSharp.sizes}
                      alt='Jay Thomson'
                    />
                  </div>
                  <h6>Jay Thomson</h6>
                  <span>Managing Partner</span>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <Meeting location={props.location.pathname}></Meeting>
            </Col>
          </Row>
        </Container>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  );
};
export default servicemesh;
